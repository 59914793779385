import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/meta/datadog-ci.png'
import EngineerIcon from '../../assets/images/home/icons/chart.svg'
import PlatformIcon from '../../assets/images/home/icons/compass.svg'
import LeaderIcon from '../../assets/images/home/icons/diamond-hand.svg'
import Layout from '../../components/Layout'
import IllustrationBlock from '../../components/blocks/IllustrationBlock'
import CTABlock from '../../components/blocks/CTABlock'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import MosaicBlock from '../../components/blocks/MosaicBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import Blurb from '../../components/system/Blurb'
import Br from '../../components/system/Br'
import { responsiveScale } from '../../styles/helpers'
import { DarkLogoBlock } from '../../components/blocks/DarkLogoBlock'
import Stack from '../../components/primitives/Stack'
import BottleneckIcon from '../../assets/images/home/icons/bottleneck-square.svg'
import Block from '../../components/primitives/Block'
import { gridSpacing } from '../../components/blocks/ColumnsBlock'
import Row from '../../components/primitives/Row'
import HeartIcon from '../../assets/images/product/overview/heart.svg'
import FastIcon from '../../assets/images/home/icons/fast.svg'
import LockIcon from '../../assets/images/home/icons/lock.svg'
import Button from '../../components/system/Button'
import GorgiasPromo from '../../components/story/gorgias/Promo'
import GorgiasResults from '../../components/story/gorgias/Results'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/overview/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    lead: file(relativePath: { eq: "images/lead/ci.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    mosaicLeft: file(
      relativePath: { eq: "images/mosaic/ci-insights-organization-mosaic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/mosaic/ci-insights-workflow-mosaic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    mosaicLeft2: file(
      relativePath: { eq: "images/github-slack/ci-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    everyTeam: file(
      relativePath: { eq: "images/home/every-team-is-different.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 612)
      }
    }
    integrateAndScale: file(
      relativePath: { eq: "images/home/easy-to-integrate.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 612)
      }
    }
  }
`

const CiInsightsPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Datadog CI Visibility vs. Swarmia"
      variant="dark"
      isNew
      description="While Datadog provides enterprise-level observability, Swarmia emphasizes simplicity and actionable notifications for the whole engineering organization."
      metaImage={MetaImage}
    >
      <HeroBlock
        title={<>Datadog CI Visibility vs.&nbsp;Swarmia</>}
        content="While Datadog provides enterprise-level observability, Swarmia emphasizes simplicity and actionable notifications for the whole engineering organization."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <DarkLogoBlock />
      <LeadBlock
        heading="Accelerate CI/CD pipelines by monitoring the metrics"
        content="While Datadog provides an overwhelming set of features for CI visibility, Swarmia's intuitive CI insights gives your whole organization the metrics and tools to reduce waiting times and costs."
      />
      <Box>
        <IllustrationBlock
          paddingTop={responsiveScale(32)}
          paddingBottom={responsiveScale(32)}
          image={getImage(data.lead)!}
          noPlaceHolder
          rounded
          shadow
        />
      </Box>
      <ColumnsBlock
        paddingTop={responsiveScale(32)}
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            title="Platform teams"
            text="Measure and improve CI performance at the org-level."
            icon={PlatformIcon}
          />,
          <Blurb
            key="2"
            title="Engineering leaders"
            text="Reduce CI costs by resolving bottlenecks in your pipelines."
            icon={LeaderIcon}
          />,
          <Blurb
            key="3"
            title="Software engineers"
            text="Dive into individual workflows to troubleshoot and fix slowness."
            icon={EngineerIcon}
          />,
        ]}
      />
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
        paddingTop={responsiveScale(64)}
      >
        <GorgiasPromo />
        <GorgiasResults />
      </Box>
      <CTABlock title="Start speeding up your CI/CD pipelines today. Available on the Lite plan." />
      <LeadBlock
        heading="Identify and resolve every key bottleneck in your CI pipelines"
        content="Start from the organization view to understand the overall performance of your CI environment. Drill down to troubleshoot issues across individual repositories and workflows."
      />
      <MosaicBlock
        title={<>Get a bird’s eye view of&nbsp;your&nbsp;pipelines</>}
        content="Swarmia gives you the views to easily evaluate the overall health and performance of your CI environment with the organization-level insights. Monitor how the most critical metrics are trending, and spot problems before things go wrong."
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title="Drill down to take action"
        content="Dive deeper into individual repositories to find the workflows with the most room for improvement. Then, analyze which parts of your workflows fail frequently or take the longest to run to identify the root causes for slowness or flakiness."
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
      />
      <MosaicBlock
        title={
          <>
            Quickly catch failed
            <Br />
            CI checks
          </>
        }
        content="Get automatic Slack alerts about failed CI checks in your PRs. To eliminate noisy pings, you’ll only get CI failure notifications after you’ve requested a review."
        image={getImage(data.mosaicLeft2)!}
      />
      <QuoteBlock person="pau" />
      <LeadBlock
        paddingTop={{ xs: 32, md: 64 }}
        icon={BottleneckIcon}
        heading={
          <>
            Works with your tools
            <Br /> and processes
          </>
        }
      />
      <Block
        paddingTop={responsiveScale(32)}
        paddingBottom={responsiveScale(92)}
      >
        <Stack
          maxWidth="largeBlock"
          space={gridSpacing}
          justifyContent="center"
          css={{ margin: 'auto' }}
        >
          <Row
            width="100%"
            space={gridSpacing}
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Box flex={1}>
              <Blurb
                title="Every team is different"
                text={
                  <>
                    There’s no single right way to build software.
                    <Br /> We adapt to your teams’ tools, rituals, and habits.
                  </>
                }
                image={getImage(data.everyTeam)}
                isLarge
              />
            </Box>
            <Box flex={1}>
              <Blurb
                title="Easy to integrate and scale"
                text={
                  <>
                    Swarmia is built to work with your internal systems
                    <Br display={{ xs: 'none', lg: 'inline' }} /> and the tools
                    you already use.
                  </>
                }
                image={getImage(data.integrateAndScale)}
                isLarge
              />
            </Box>
          </Row>
          <Row space={gridSpacing} flexDirection={{ xs: 'column', md: 'row' }}>
            <Row
              space={gridSpacing}
              flex={1}
              flexDirection={{
                xs: 'column',
                sm: 'row',
                md: 'column',
                lg: 'row',
              }}
            >
              <Box flex={1}>
                <Blurb
                  icon={HeartIcon}
                  title="No harmful metrics"
                  text="Swarmia is not a tool for spying on developers. We only surface healthy, research-backed insights."
                />
              </Box>
              <Box flex={1}>
                <Blurb
                  icon={FastIcon}
                  title={
                    <>
                      Fast onboarding
                      <Br /> and flexible rollout
                    </>
                  }
                  text="Setting up Swarmia is easy. You can either do it yourself or get support from our customer success team."
                />
              </Box>
            </Row>
            <Box flex={1}>
              <Blurb
                icon={LockIcon}
                title="Designed security-first"
                text={
                  <>
                    <Box>
                      Swarmia is{' '}
                      <Box.span fontWeight={700}>SOC 2 Type 2</Box.span>{' '}
                      compliant and we conduct security audits twice a year to
                      keep your data safe. We’re happy to support comprehensive
                      security reviews, and work with your security team before
                      you connect to Swarmia.
                    </Box>
                    <Button
                      href="/security/"
                      variant="leadLink"
                      marginTop={12}
                      arrow
                      fontSize={18}
                    >
                      How we keep your data secure
                    </Button>
                  </>
                }
                isLarge
                isFullHeight={false}
              />
            </Box>
          </Row>
        </Stack>
      </Block>
      <QuoteBlock
        person="walter"
        button={{ text: 'Read story', href: '/story/miro/' }}
      />
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock title="Get started with Swarmia for free." />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/continuous-integration/',
            '/blog/measuring-software-development-productivity/',
            '/blog/balancing-engineering-investments/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default CiInsightsPage
