import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import PromoBlurb from '../PromoBlurb'

const GorgiasHeader = ({ featured }: { featured?: boolean }) => {
  const data = useStaticQuery(query)
  const photo = data.hero.childImageSharp.gatsbyImageData
  return (
    <PromoBlurb
      photo={photo}
      company="Gorgias"
      heading="Gorgias saves hundreds of hours of manual work with Swarmia"
      subheading="Gorgias has been using Swarmia for years to empower everyone in the engineering organization with the right data and continuously improve the speed at which they bring new features to the market."
      featured={featured}
    />
  )
}

export default GorgiasHeader

const query = graphql`
  {
    hero: file(relativePath: { eq: "images/story/gorgias/hero-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 480, height: 540, layout: FIXED)
      }
    }
  }
`
